:root{
  --nav-bg-color:#34495E;
  --forcus-border-color:#314253;
  --text-color: white;
  --search-bg-color:#304152;
  --nav-active-color: #FFBD54;
  --bg-color:#1D2935;
  --text-area-color:#304152;
  --item-bg:#141D25;
  font-size: 16px;
  --nav-size: 70px;
}
html,body{
  background-color: var(--bg-color);
}

.navbar{
  width: var(--nav-size);
  /* min-width: 70px; */
  background-color: var(--nav-bg-color);
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  z-index: 999;
}
.nav-item{
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--nav-size);
  min-width: 70px;
  height: var(--nav-size);
  min-height: 70px;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
  font-size: 2rem;
}
.nav-item:hover{
  font-size: 2.5rem;
}
.active{
  color: var(--nav-active-color);
}
.colorstrip{
  border-left: 10px var(--nav-active-color) solid;
  padding-left: 20px;
}
.search-input{
  background-color: var(--search-bg-color) !important;
}
.chip{
  background-color: yellowgreen;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  margin: 5px;
  width: fit-content;
}
.chip>p{
  white-space: nowrap;
}
.linkedchip{
  transition: all 0.4s;
}
.linkedchip:hover{
  transform: scale(1.2);
}
.sm-show{
  display: none;
}
.itembox{
  background-color: var(--item-bg);
  transition: all 0.5s;
}
.itembox:hover{
  cursor: pointer;
}
.done{
  filter: blur(3px) brightness(0.7);
}
.chipsetup{
  transition: all 0.2s;
}
.chipsetup:hover{
  filter: brightness(1.2) blur(2px);
  cursor: pointer;
}
@media (max-width: 575.98px) { 
  .navbar{
    height: var(--nav-size);
    width: 100vw;
    background-color: var(--nav-bg-color);
    min-height: 70px;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
  }
  .nav-bottom{
    display: none;
  }
  .nav-top{
    display: flex;
    width: 100vw;
  }
  .nav-item{
    flex-grow: 1;
  }
  .sm-show{
    display: flex;
  }
  footer{
    width: 100vw;
    height: 80px;
    display: block;
  }
 }


@media (min-width: 576px) and (max-width: 767.98px) { 

 }


@media (min-width: 768px) and (max-width: 991.98px) { 

 }


@media (min-width: 992px) and (max-width: 1199.98px) { 
  
 }

@media (min-width: 1200px) { 

 }

